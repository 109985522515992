import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { Formik } from "formik";

import CustomModal from "@/components/CustomModal";

import Form from "./form";

import { validationSchema } from "./validations";
import { LOGIN_ATTEMPT_ERR_MESSAGE, NO_NET_ERR_MESSAGE } from "./constants";
import useAuth from "../../hooks/useAuth";
import { auth0Client } from "../../utilities/auth0";

const Login = (props: any) => {
  const { setAccessToken, isLoggedIn } = useAuth();
  let history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const reinitialize = false;
  const initialValues = {
    email: "",
    password: "",
  };

  const handleOk = () => {
    setShowModal(false);
    setLoading(false);
  };

  const handleLogin = (values) => {
    login(values.email, values.password);
  };

  const login = (username, password) => {
    setLoading(true);
    auth0Client.client.login(
      {
        realm: "Username-Password-Authentication",
        username,
        password,
      },
      (err, authResult) => {
        if (err) {
          if (err.code === "too_many_attempts") {
            setErrMessage(LOGIN_ATTEMPT_ERR_MESSAGE);
          } else if (err.code === "invalid_grant") {
            setIsAccessDenied(true);
            setLoading(false);
            return;
          } else if (err.code === null) {
            setErrMessage(NO_NET_ERR_MESSAGE);
          } else {
            setErrMessage(err.description);
          }
          setShowModal(true);

          return;
        }
        if (authResult) {
          console.log(authResult);
          setAccessToken(authResult.accessToken);
          return history.push("/brand-management");
        }
        setLoading(false);
      }
    );
  };

  if (isLoggedIn()) {
    return <Redirect to="brand-management" />;
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={reinitialize}
        onSubmit={(values: any) => {
          handleLogin(values);
        }}
        render={(formikBag) => (
          <Form
            {...{
              formikBag,
              isAccessDenied,
              loading,
            }}
          />
        )}
      />
      <CustomModal
        closable={false}
        maskClosable={false}
        noCancelBtn
        isErrorIcon
        visible={showModal}
        titleMessage="Login Error"
        message={errMessage}
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
};

export default Login;
